
import React, {useState} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"

const formatTools = require("../../../../lib/formatTools");
const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="nnproductrequest"


const docstatuslist = [
	{"value":1,"display":"Draft"},
	{"value":2,"display":"For Approval"},
	{"value":3,"display":"Approved"},
	{"value":4,"display":"Declined"},
	{"value":5,"display":"For Adjustments"}
];

const searchFields = [
	{"label":"Image", "dbfield": "nnproductrequest_image", "type": "image", "filtertype": "image"},
	{"label":"Seller", "dbfield": "onsorganization_name", "type": "text", "filtertype": "textbox"},
	{"label":"Seller SKU", "dbfield": "nnproductrequest_orgsku", "type": "text", "filtertype": "textbox"},
	{"label":"Full Name / Display", "dbfield": "nnproductrequest_fullname", "type": "text", "filtertype": "textbox"}
];

const searchPromoFields = [
	{"label":"Promo Item", "dbfield": "nnproductrequest_promoitem", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Variant/Promo Display", "dbfield": "nnproductrequest_subname", "type": "text", "filtertype": "text"},
	{"label":"Promo Image", "dbfield": "nnproductrequest_promoimage", "type": "image", "filtertype": "image"},
];

const searchDetailsFields = [
	{"label":"Status", "dbfield": "docstatus_id", "type": "combo", "filtertype": "combo", "options": docstatuslist},
	{"label":"Price", "dbfield": "nnproductrequest_price", "type": "currency", "filtertype": "currency"},

	{"label":"Brand", "dbfield": "nnbrand_name", "type": "text", "filtertype": "textbox"},

	{"label":"Last Update", "dbfield": "nnproductrequest_lastupdate", "type": "datetime", "filtertype": "datetime"},
];


const historySubformFields = [
	{
		"subformid": "nnproductrequesthistory",
		"label": "Notes/Feedback History",
		"table": "nnproductrequesthistory",
		"sort": "nnproductrequesthistory_created desc",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Date Time",
				"field": "nnproductrequesthistory_created",
				"subtotalid": "",
				"value": "",
				"input": "datetime",
				"mode": "readonly"
			},
			{
				"label": "Status",
				"field": "docstatus_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
			{
				"label": "Details",
				"field": "nnproductrequesthistory_details",
				"subtotalid": "",
				"value": "",
				"input": "textarea",
				"mode": "readonly"
			}
		],
		"footerlist": [
		]
	}
];

const ProductRequestEditorPage = ({location, allowadd, formFields, customSearchFilter, editSubFormFields, customSubmit, customSetFormData, customValidateValues}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [docstatusid, setDocstatusid] = useState(0);
	const [notestr, setNotestr] = useState("");

	function requestCustomSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		const clearnotesfield = allowadd?"nnproductrequest_feedback":"nnproductrequest_usernotes";
		const notesfield = allowadd?"nnproductrequest_usernotes":"nnproductrequest_feedback";
		const updatetimestr = formatTools.getDateStr();
		var flagstatuschanged = false;
		var curdocstatus = docstatusid;
		var curnotestr = notestr;
		var pkid = 0;

		if (typeof customSubmit === "undefined") {
			callback({"status":"Error", "message":"Invalid configuration"});
			return;
		}

		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}

		if (params.hasOwnProperty(notesfield)) {
			if (params[notesfield]) {
				curnotestr = params[notesfield];
			}
		}

		if (params.hasOwnProperty('docstatus_id')) {
			if (params.docstatus_id) {
				curdocstatus = parseInt(params.docstatus_id, 10);
				flagstatuschanged = (docstatusid !== curdocstatus);
				if (curdocstatus === 3) {
					// Approved
					if (curnotestr.length > 0) {
						curnotestr = "Product Created; "+curnotestr;
					} else {
						curnotestr = "Product Created";
					}
				}
			}
		}

		if (pkid === 0) {
			if (allowadd === false) {
				// Approver mode, request needs to be already existing
				callback({"status":"Error", "message":"Invalid product request"});
				return;
			}
			flagstatuschanged = true;
			params.nnproductrequest_created = updatetimestr;
			params.nnproductrequest_active = 1;
			if (curnotestr.length > 0) {
				curnotestr = "Created; "+curnotestr;
			} else {
				curnotestr = "Created";
			}
		}
		if (flagstatuschanged) {
			params[clearnotesfield] = ""; // Clear past message from requestor/approver
		}

		params.nnproductrequest_lastupdate = updatetimestr;
		customSubmit(entity, params, payloadlist, subformpayloadlist, token, function(response) {
			if (flagstatuschanged) {
				handleRequestHistory(response.documentid, token, curdocstatus, updatetimestr, curnotestr, function(historyres) {
					callback(response);
				});
				return;
			}
			callback(response);
		});

	}

	function handleRequestHistory(pkid, token,docstatus_id, updatetimestr, details, callback)
	{
		webappsAPI.saveRecord("NNPRODUCTREQUESTHISTORY", {
				"nnproductrequest_id": pkid,
				"nnproductrequesthistory_created": updatetimestr,
				"docstatus_id":docstatus_id,
				"nnproductrequesthistory_details": details
			}, token).then(historyresponse => {
				callback({"status":"OK", "message":"Status Changed"});
			}); // add history
	}


	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (inputlist[idx].field === "docstatus_id") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (allowadd === true) {
				// request
				if (docstatusid <= 1) {
					tmpfield.options = [
						{"value":1,"display":"Draft"},
						{"value":2,"display":"For Approval"}
					];
				} else if (docstatusid === 5) {
					tmpfield.options = [
						{"value":5,"display":"For Adjustments"},
						{"value":2,"display":"For Approval"}
					];
				} else if (docstatusid === 2) {
					tmpfield.options = [
						{"value":2,"display":"For Approval"},
						{"value":1,"display":"Back to Draft"}
					];
				} else {
					tmpfield.mode = "readonly";
				}
			} else {
				// Approver
				if (docstatusid === 2) {
					tmpfield.options = [
						{"value":5,"display":"For Adjustments"},
						{"value":4,"display":"Declined"},
						{"value":3,"display":"Approve"}
					];
				} else {
					tmpfield.mode = "readonly";
				}
			}
			return tmpfield;
		} else if ((docstatusid !== 0 && docstatusid !== 1 && docstatusid !== 5) && allowadd === true) {
			// Disable editing for requestor if not in Draft
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		} else if (docstatusid !== 2 && allowadd === false) {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		}
		return inputlist[idx];
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		const notesfield = allowadd?"nnproductrequest_usernotes":"nnproductrequest_feedback";
		var newstatusid  = 0;
		var newnotesstr = "";
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "docstatus_id") {
				newstatusid = parseInt(mainform[idx].value,10);
			} else if (mainform[idx].field === notesfield) {
				newnotesstr = mainform[idx].value;
			}
			idx++;
		}
/*
		{"value":1,"display":"Draft"},
		{"value":2,"display":"For Approval"},
		{"value":3,"display":"Approved"},
		{"value":4,"display":"Declined"},
		{"value":5,"display":"For Adjustments"}
*/
		if (newstatusid !== docstatusid && newnotesstr.length < 1) {
			// Require feedback for certain status
			if (newstatusid === 4 || newstatusid === 5) {
				return {"status":"Error", "message":"Please provide feedback/details"};
			} else if (newstatusid === 2 && docstatusid === 5) {
				return {"status":"Error", "message":"Please provide notes/response"};
			}
		}
		if (typeof customValidateValues !== "undefined") {
			return customValidateValues(mainform, subformlist, subformfooterlist);
		}
		return {"status":"OK"};
	}

	function requestCustomSetFormData(newformdata)
	{
		const notesfield = allowadd?"nnproductrequest_usernotes":"nnproductrequest_feedback";
		var tmpnotesstr = "";
		var newstatusid = 0;
		if (newformdata.hasOwnProperty("docstatus_id")) {
			if (newformdata.docstatus_id) {
				newstatusid = newformdata.docstatus_id;
			}
		}
		if (newformdata.hasOwnProperty(notesfield)) {
			if (newformdata[notesfield]) {
				tmpnotesstr = newformdata[notesfield];
			}
		}

		setNotestr(tmpnotesstr);
		setDocstatusid(newstatusid);

		if (typeof customSetFormData !== "undefined") {
			customSetFormData(newformdata);
		}
	}

	return <Layout location={location}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={allowadd}
				allowdelete={false}
				mobilerowtitlefield={["nnproductrequest_fullname"]}
				pagetitle={pagetitle}
				userSearchParam={{"orderby":"nnproductrequest_lastupdate desc"}}
				customSubmit={requestCustomSubmit}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={formValidateValues}
				customSetFormData={requestCustomSetFormData}
				searchFields={allowadd?searchFields.concat(searchDetailsFields):searchFields.concat(searchPromoFields).concat(searchDetailsFields)}
				userSearchFilter={customSearchFilter}
				editFields={formFields}
				editSubFormFields={editSubFormFields.concat(historySubformFields)}
				entity={ENTITYID}
				token={token} />
		</Layout>

}

export default ProductRequestEditorPage;
